import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { api } from "./page/contexts/api";
import Home from "./template/home";
import TemplateBorwserview from "./template/TemplateBorwserview"
import TemplateMobile from "./template/TemplateMobile"
import Form from "./page/Form";
import Index from "./page/Index";
// import Indexmobile from "./mobile/ApprovedRequestIn";
import axios from "axios";
import { LoginContext } from "./page/contexts/LoginContext";
import FormRecruitmentRequisition from "./page/FormRecruitmentRequisition";
import CreateForm from "./page/CreateForm";
import FormList from "./page/FormList";
import EditForm from "./page/EditForm";
import Formmain from "./page/Formmain";
import RequestForm from "./page/RequestForm";
import RequestFormMobile from "./mobile/RequestForm"
import ViewForm from "./page/ViewForm";
import ViewApproved from "./page/ViewApproved";
import Cookies from 'js-cookie';
import Login from "./page/Login";
import setAuthToken from "./setAuthToken"
import { Department } from "./page/Department";
import { Departmentmobile } from "./mobile/Department";
import { UserusingForm } from "./page/UserusingForm";
import UserFormList from "./page/UserFormList";
import { UserEditFrom } from "./page/UserEditFrom";
import { AddEmployee } from "./mobile/AddEmployee";
import { Doc_no } from "./page/Doc_no";
import { UserCheckDoc_no } from "./page/UserCheckDoc_no";
import { ListFormFromRequest } from "./page/ListFormFromRequest";
import { Signature } from "./page/Signature";
import Viewdetailform from "./page/Viewdetailform";
import Testing from "./page/Testing";
import { ApprovedReques } from "./page/ApprovedReques";
import { ApprovedRequesmobile } from "./mobile/ApprovedReques";
import UserFormListOut from "./page/UserFormListOut";
import UserFormListOutMobile from "./mobile/UserFormListOut";
import { Doc_noOut } from "./page/Doc_noOut";
import { UserCkeckDoc_noOut } from "./components/UserCkeckDoc_noOut";
import { ApprovedRequesOut } from "./page/ApprovedRequesOut";
import { ApprovedRequesOutmobile } from "./mobile/ApprovedRequesOut";
import { IncomingDocument } from "./page/IncomingDocument";
import { IncomingDocumentmobile } from "./mobile/IncomingDocument";
import { ApprovedRequestIn } from "./page/ApprovedRequestIn";
import { ApprovedRequestInmobile } from "./mobile/ApprovedRequestIn";
import FormListTest from "./page/FormListTest";
import FormListTestmobile from "./mobile/FormListTest";
import { BrowserView, MobileView } from 'react-device-detect';
import { EditFormTest } from "./mobile/EditFormTest";
import { httpErrorHandler } from "./middleware/httpErrorHandler";
import { ModalForbidden } from "./components/ModalForbidden";
axios.defaults.baseURL = api;
function App() {
  const [listItemCart, setListItemCart] = useState([])
  const [dataList, setDataList] = useState([])
  const [open, setOpen] = useState(false);
  const [listOpent, setListOpent] = useState(false);
  const [listopenForm, setListopenForm] = useState(false)
  const [showformscreen, setShowformscreen] = useState(false)
  const [showforcreateform, setShowforcreateform] = useState(false)
  const [showformEditScreen, setshowformEditScreen] = useState(false)
  const [showViewFormScreen, setShowViewFormScreen] = useState(false)
  const [showUserusingFormScreen, setShowUserusingFormScreen] = useState(false)
  const [showUserEditFormScreen, setShowUserEditFormScreen] = useState(false)
  const [showUserCheckFormScreen, setShowUserCheckFormScreen] = useState(false)
  const [showUserCheckFormScreenOut, setShowUserCheckFormScreenOut] = useState(false)
  const [document_status, setDocument_status] = useState('')
  const [notifications, setNotifications] = useState('')
  const [countitem, setCountitem] = useState({})
  const [openingsetting, setOpeningsetting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [id, setId] = useState('')
  const OnloadListItemcard = () => {
    axios.get('/api/document-management/document/get-itemsCart').then((data) => {
      setListItemCart([...data?.data?.results])
    }).catch((err) => {
      httpErrorHandler(err, setIsModalOpen)
    })
  }
  const Onloadnotifications = () => {
    axios.get('/api/document-management/func/get-notifications').then((data) => {

      setCountitem(data?.data)
      setNotifications(data?.data?.total)

    }).catch((err) => {
      httpErrorHandler(err, setIsModalOpen)
    })
  }
  const handleCloseOpenting = () => {
    setOpeningsetting(false)
  }
  const opening = () => {
    setOpeningsetting(!openingsetting)
  }
  useEffect(() => {
    let users = Cookies.get("user");
    if (!users) {
      window.location.assign('https://testing.psvsystem.com/');
    } else {
      let data = JSON.parse(users)
      if (data.token) {
        setAuthToken(data.token, setIsModalOpen)
      }
    }
    Onloadnotifications()
  }, []);
  // if (!users) {
  //   return (
  //     <ModalForbidden
  //       isModalOpen={isModalOpen}
  //     />
  //   )
  // }
  // let users = Cookies.get("user");
  // if (!users) {
  //   return (
  //     <Login />
  //   )
  // } else {
  //   let data = JSON.parse(users)
  //   if (data.token) {
  //     setAuthToken(data.token)
  //   }
  // }
  return (
    <div>
      <LoginContext.Provider value={{
        listItemCart,
        OnloadListItemcard,
        dataList,
        setDataList,
        open,
        setOpen,
        listOpent,
        setListOpent,
        listopenForm,
        setListopenForm,
        showformscreen,
        setShowformscreen,
        showforcreateform,
        setShowforcreateform,
        id,
        setId,
        showformEditScreen,
        setshowformEditScreen,
        showViewFormScreen,
        setShowViewFormScreen,
        showUserusingFormScreen,
        setShowUserusingFormScreen,
        showUserEditFormScreen,
        setShowUserEditFormScreen,
        showUserCheckFormScreen,
        setShowUserCheckFormScreen,
        setShowUserCheckFormScreenOut,
        showUserCheckFormScreenOut,
        setDocument_status,
        document_status,
        notifications,
        countitem,
        Onloadnotifications,
        openingsetting,
        handleCloseOpenting,
        opening
      }}>
        <Router>
          <BrowserView>
            <TemplateBorwserview>
              <Routes>
                <Route exact path="/" element={< Index />}></Route>
                <Route exact path="/Index" element={< Index />}></Route>
                <Route exact path="/Form/:id" element={< Form />}></Route>
                <Route exact path="/EditForm/:id" element={< EditForm />}></Route>
                <Route exact path="/Viewdetailform/:id" element={< Viewdetailform />}></Route>
                <Route exact path="/Department/:id" element={< Department />}></Route>
                <Route exact path="/UserEditFrom/:id" element={< UserEditFrom />}></Route>
                <Route exact path="/UserCheckDoc_no/:id" element={< UserCheckDoc_no />}></Route>
                <Route exact path="/UserCheckDoc_noOut/:id" element={< UserCkeckDoc_noOut />}></Route>
                <Route exact path="/FormList" element={< FormList />}></Route>
                <Route exact path="/FormListTest" element={< FormListTest />}></Route>
                <Route exact path="/AddEmployee" element={< AddEmployee />}></Route>
                <Route exact path="/Doc_no" element={< Doc_no />}></Route>
                <Route exact path="/Doc_noOut" element={< Doc_noOut />}></Route>
                <Route exact path="/UserFormList" element={< UserFormList />}></Route>
                <Route exact path="/CreateForm" element={< CreateForm />}></Route>
                <Route exact path="/Formmain" element={< Formmain />}></Route>
                <Route exact path="/RequestForm" element={< RequestForm />}></Route>
                <Route exact path="/ViewForm/:id" element={< ViewForm />}></Route>
                <Route exact path="/UserusingForm/:id" element={< UserusingForm />}></Route>
                <Route exact path="/ViewApproved" element={< ViewApproved />}></Route>
                <Route exact path="/FormRecruitmentRequisition" element={< FormRecruitmentRequisition />}></Route>
                <Route exact path="/ListFormFromRequest" element={< ListFormFromRequest />}></Route>
                <Route exact path="/ApprovedRequest" element={<ApprovedReques />}></Route>
                <Route exact path="/ApprovedRequestOut" element={<ApprovedRequesOut />}></Route>
                <Route exact path="/ApprovedRequestIn" element={<ApprovedRequestIn />}></Route>
                <Route exact path="/UserFormListOut" element={<UserFormListOut />}></Route>
                <Route exact path="/IncomingDocument" element={<IncomingDocument />}></Route>
                <Route exact path="/Testing" element={<Testing />}></Route>
              </Routes>
            </TemplateBorwserview>
          </BrowserView>
          <MobileView>
            <TemplateMobile>
              <Routes>
                <Route exact path="/" element={<  IncomingDocumentmobile />}></Route>
                <Route exact path="/Index" element={< IncomingDocumentmobile />}></Route>
                <Route exact path="/Form/:id" element={< Form />}></Route>
                <Route exact path="/EditForm/:id" element={< EditForm />}></Route>
                <Route exact path="/EditFormMobile/:id" element={< EditFormTest />}></Route>
                <Route exact path="/Viewdetailform/:id" element={< Viewdetailform />}></Route>
                <Route exact path="/Departmentmobile/:id" element={< Departmentmobile />}></Route>
                <Route exact path="/UserEditFrom/:id" element={< UserEditFrom />}></Route>
                <Route exact path="/UserCheckDoc_no/:id" element={< UserCheckDoc_no />}></Route>
                <Route exact path="/UserCheckDoc_noOut/:id" element={< UserCkeckDoc_noOut />}></Route>
                <Route exact path="/FormList" element={< FormList />}></Route>
                <Route exact path="/FormListTest" element={< FormListTestmobile />}></Route>
                <Route exact path="/AddEmployee" element={< AddEmployee />}></Route>
                <Route exact path="/Doc_no" element={< Doc_no />}></Route>
                <Route exact path="/Doc_noOut" element={< Doc_noOut />}></Route>
                <Route exact path="/UserFormList" element={< UserFormList />}></Route>
                <Route exact path="/CreateForm" element={< CreateForm />}></Route>
                <Route exact path="/Formmain" element={< Formmain />}></Route>
                <Route exact path="/RequestForm" element={< RequestFormMobile />}></Route>
                <Route exact path="/ViewForm/:id" element={< ViewForm />}></Route>
                <Route exact path="/UserusingForm/:id" element={< UserusingForm />}></Route>
                <Route exact path="/ViewApproved" element={< ViewApproved />}></Route>
                <Route exact path="/FormRecruitmentRequisition" element={< FormRecruitmentRequisition />}></Route>
                <Route exact path="/ListFormFromRequest" element={< ListFormFromRequest />}></Route>
                <Route exact path="/ApprovedRequest" element={<ApprovedRequesmobile />}></Route>
                <Route exact path="/ApprovedRequestOut" element={<ApprovedRequesOutmobile />}></Route>
                <Route exact path="/ApprovedRequestIn" element={<ApprovedRequestInmobile />}></Route>
                <Route exact path="/UserFormListOutMobile" element={<UserFormListOutMobile />}></Route>
                <Route exact path="/IncomingDocumentmobile" element={<IncomingDocumentmobile />}></Route>
                <Route exact path="/Testing" element={<Testing />}></Route>
              </Routes>
            </TemplateMobile>
          </MobileView>
          {/* <Home>
          <Routes>
     
        </Home> */}
        </Router>
      </LoginContext.Provider>
    </div>
  );
}

export default App;
