import React, { useState, useRef, useContext } from "react";
import BorderAllIcon from '@material-ui/icons/BorderAll';
import InputIcon from '@material-ui/icons/Input';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PrintIcon from '@material-ui/icons/Print';
import { Rnd } from 'react-rnd';
import { Input } from "./Formmenu/Input";
import { ModalTable } from "./ModalTable";
import { TableMenu } from "./Formmenu/TableMenu";
import { LoginContext } from "../page/contexts/LoginContext";
import ChatIcon from '@material-ui/icons/Chat';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { TextMenu } from "./Formmenu/TextMenu";
import { CheckBoxMenu } from "./Formmenu/CheckBoxMenu";
import { ImageMenu } from "./Formmenu/ImageMenu";
import CancelIcon from '@material-ui/icons/Cancel';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { ModalSaveTable } from "./ModalSaveTable";
import axios from "axios";
export const CreateFormTest = () => {

  const componentRef = useRef();


  const handlePrintToPDF = () => {
    const input = componentRef.current;
    console.log("input=", input)
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/PNG');
        console.log('imgData', imgData)
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; // A4 width
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        // pdf.save('document.pdf');
        window.open(pdf.output('bloburl'), '_blank');
      });
    }
  };
  const [active, setActive] = useState("");
  const [listtext, setListText] = useState([])
  const [listtextw, setListTextw] = useState([])
  const [show, setShow] = useState(false);
  const [tableRow, setTableRow] = useState('')
  const [tableColumn, setTableColumn] = useState('')
  const [showdeltable, setShowdeltable] = useState(true)
  const [showdeltable1, setShowdeltable1] = useState(false)
  const [showdeltable2, setShowdeltable2] = useState(false)
  const [editcheckboxvalues, setEditcheckboxvalues] = useState('')
  const [widthsize, setWidthsize] = useState('')
  const [heightsize, setHeightsize] = useState('')
  const [dataText, setDataText] = useState([])
  const [index, setIndex] = useState('')
  const [index1, setIndex1] = useState('')
  const [tables, setTables] = useState([[]])
  const [tables1, setTables1] = useState([[]])
  const [tables2, setTables2] = useState([[]])
  const [datatable, setDatatable] = useState([])
  const [datatable1, setDatatable1] = useState([])
  const [datatable2, setDatatable2] = useState([])
  const [getindex_first, setGetindex_first] = useState([])
  const [getindex_second, setGetindex_second] = useState([])
  const [getindex_third, setGetindex_third] = useState([])
  const [get_index, setGet_index] = useState('')
  const [menucondigions, setMenucondigions] = useState('')
  const [inputValues, setInputValues] = useState('')
  const [inputValues1, setInputValues1] = useState('')
  const [editlable, setEditlable] = useState('')
  const [fontSize, setFontSize] = useState('')
  const [datacheckbox, setDatacheckbox] = useState([])
  const [uniqueCombinationCount_first, setUniqueCombinationCount_first] = useState(0);
  const [uniqueCombinationCount_second, setUniqueCombinationCount_second] = useState(0);
  const [uniqueCombinationCount_third, setUniqueCombinationCount_third] = useState(0);
  const [uniqueCombinations_first, setUniqueCombinations_first] = useState(new Set());
  const [uniqueCombinations_second, setUniqueCombinations_second] = useState(new Set());
  const [uniqueCombinations_third, setUniqueCombinations_third] = useState(new Set());
  const [selectedCells_first, setSelectedCells_first] = useState([])
  const [selectedCells_second, setSelectedCells_second] = useState([])
  const [selectedCells_third, setSelectedCells_third] = useState([])
  const [file, setFile] = useState();
  const [selectedImage, setSelectedImage] = useState([])
  const [ing_width, setIng_width] = useState('')
  const [ing_heigh, setIng_heigh] = useState('')
  const [pictureUrl, setPictureUrl] = useState(null);
  const [showsave, setShowsave] = useState(false);
  const [saveAs, setSaveAs] = useState('')
  const [isLoading, setIsLoading,] = useState(false);
  const handleClosedel = () => {
    setShow(false);
  }
  const {
    setShowformscreen,
  } = useContext(LoginContext)
  const OncloseCreateformScreen = () => {

    setShowformscreen(false)
  }
  const handlesaveShow = () => setShowsave(true)
  const handleShow = () => setShow(true);
  const OnAddText = () => {
    if (listtext.length === 0) {
      setListText([...listtext, { name: "", positionX: 100, positionY: 100, width: 200, height: 25, type: 'text' }]);
    } else {
      const positionY_number = (listtext.length - 1) * 100 + 100;
      setListText([...listtext, { name: "", positionX: 100, positionY: positionY_number, width: 200, height: 25, type: 'text' }]);
    }
  };
  const onDragStopinput = (e, d, index) => {
    const cloneDatas = [...listtext]
    const cloneData = { ...listtext[index] }
    cloneData.positionX = d.x;
    cloneData.positionY = d.y
    cloneDatas[index] = cloneData
    setListText([...cloneDatas])
  }
  const onDragStoptable = (e, d, index, key_values) => {
    if (key_values === 'one') {
      const cloneDatas = [...datatable]
      const cloneData = { ...datatable[index] }
      cloneData.positionX = d.x;
      cloneData.positionY = d.y
      cloneDatas[index] = cloneData
      setDatatable([...cloneDatas])

    } else if (key_values === 'two') {
      const cloneDatas = [...datatable1]
      const cloneData = { ...datatable1[index] }
      cloneData.positionX = d.x;
      cloneData.positionY = d.y
      cloneDatas[index] = cloneData
      setDatatable1([...cloneDatas])

    } else {
      const cloneDatas = [...datatable2]
      const cloneData = { ...datatable2[index] }
      cloneData.positionX = d.x;
      cloneData.positionY = d.y
      cloneDatas[index] = cloneData
      setDatatable2([...cloneDatas])
    }
  }
  const onDragStopDataText = (e, d, index) => {
    const cloneDatas = [...dataText]
    const cloneData = { ...dataText[index] }
    cloneData.positionX = d.x;
    cloneData.positionY = d.y
    cloneDatas[index] = cloneData
    setDataText([...cloneDatas])
  }
  const onDragStopcheckbox = (e, d, index) => {
    const cloneDatas = [...datacheckbox]
    const cloneData = { ...datacheckbox[index] }
    cloneData.positionX = d.x;
    cloneData.positionY = d.y
    cloneDatas[index] = cloneData
    setDatacheckbox([...cloneDatas])
  }
  const onDragImagelogo = (e, d, index) => {
    const cloneDatas = [...selectedImage]
    const cloneData = { ...selectedImage[index] }
    cloneData.positionX = d.x;
    cloneData.positionY = d.y
    cloneDatas[index] = cloneData
    setSelectedImage([...cloneDatas])
  }
  const OnClickCheckimage = (index) => {
    setMenucondigions('Image')
    setGet_index(index)
    const cloneData = selectedImage[index]
    setIng_width(cloneData.width)
    setIng_heigh(cloneData.height)
  }
  const changeTextImage_width = (value, key, index) => {
    setIng_width(value)
    const object = { ...selectedImage[index] };
    object[key] = value;
    const cloneData = [...selectedImage];
    cloneData[index] = { ...object };
    setSelectedImage([...cloneData])
  }
  const changeTextImage_height = (value, key, index) => {
    setIng_heigh(value)
    const object = { ...selectedImage[index] };
    object[key] = value;
    const cloneData = [...selectedImage];
    cloneData[index] = { ...object };
    setSelectedImage([...cloneData])
  }
  const OnClickCheckbox = (index) => {
    setMenucondigions('CheckBox')
    setGet_index(index)
    const cloneData = datacheckbox[index]
    setEditcheckboxvalues(cloneData.name)
  }
  const deletecheckbox = (index) => {
    setEditcheckboxvalues('')
    const cloneData = [...datacheckbox]
    cloneData.splice(index, 1)
    setDatacheckbox([...cloneData])
  }
  const onClickDataText = (index) => {
    setMenucondigions('Text')
    setGet_index(index)
    const cloneData = dataText[index]
    setEditlable(cloneData.name)
    setFontSize(cloneData.font)
  }
  const Onclicktableindex = (index, key) => {
    setMenucondigions('table')
    if (key === 'one') {
      setShowdeltable(true)
      setShowdeltable1(false)
      setShowdeltable2(false)
      setGet_index(index)

    } else if (key === 'two') {
      setShowdeltable(false)
      setShowdeltable1(true)
      setShowdeltable2(false)
      setGet_index(index)

    } else {
      setShowdeltable(false)
      setShowdeltable1(false)
      setShowdeltable2(true)
      setGet_index(index)

    }
  }
  const changeText_values_table = (value, key, index1, index, kev_values) => {
    if (kev_values === 'one') {
      const cloneTables = [...tables]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = value;
      const cloneData = [...tables]
      setTables([...cloneData])
    } else if (kev_values === 'two') {
      const cloneTables = [...tables1]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = value;
      const cloneData = [...tables1]
      setTables1([...cloneData])
    } else {
      const cloneTables = [...tables2]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = value;
      const cloneData = [...tables2]
      setTables2([...cloneData])
    }
  };
  const changeTextwidth = (value, key, index, index1, key_values) => {
    setWidthsize(value)
    if (key_values === 'one') {
      const cloneTables = [...tables]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = value;
      const cloneData = [...tables]
      setTables([...cloneData])
    } else if (key_values === 'two') {
      const cloneTables = [...tables1]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = value;
      const cloneData = [...tables1]
      setTables1([...cloneData])

    } else {
      const cloneTables = [...tables2]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = value;
      const cloneData = [...tables2]
      setTables2([...cloneData])
    }
  };
  const changeTextheight = (value, key, index, index1, key_values) => {
    setHeightsize(value)
    if (key_values === 'one') {
      const cloneTables = [...tables]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = value;
      const cloneData = [...tables]
      setTables([...cloneData])
    } else if (key_values === 'two') {
      const cloneTables = [...tables1]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = value;
      const cloneData = [...tables1]
      setTables1([...cloneData])
    } else {
      const cloneTables = [...tables2]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = value;
      const cloneData = [...tables2]
      setTables2([...cloneData])
    }
  };
  const MergeCellcolumn = (key) => {
    if (key === 'one') {
      const resultArray = getindex_first.slice(0, 1);
      const index = resultArray[0].indexrow
      const index1 = resultArray[0].indexco
      const key = 'rows'
      const cloneTables = [...tables]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = uniqueCombinationCount_first;
      const cloneData = [...tables]
      setTables([...cloneData])
      const Array = getindex_first.slice(1);
      const clone_Tables = [...tables];
      Array.forEach((e) => {
        const deleteObject = clone_Tables[e.indexco];
        deleteObject.splice(e.indexrow, 1);
      });
      setTables([...clone_Tables]);
      setGetindex_first([])
      setSelectedCells_first([])
      setUniqueCombinationCount_first([])
    } else if (key === 'two') {
      const resultArray = getindex_second.slice(0, 1);
      const index = resultArray[0].indexrow
      const index1 = resultArray[0].indexco
      const key = 'rows'
      const cloneTables = [...tables1]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = uniqueCombinationCount_second;
      const cloneData = [...tables1]
      setTables1([...cloneData])
      const Array = getindex_second.slice(1);
      const clone_Tables = [...tables1];
      Array.forEach((e) => {
        const deleteObject = clone_Tables[e.indexco];
        deleteObject.splice(e.indexrow, 1);
      });
      setTables1([...clone_Tables]);
      setGetindex_second([])
      setSelectedCells_second([])
      setUniqueCombinationCount_second([])

    } else {
      const resultArray = getindex_third.slice(0, 1);
      const index = resultArray[0].indexrow
      const index1 = resultArray[0].indexco
      const key = 'rows'
      const cloneTables = [...tables2]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = uniqueCombinationCount_third;
      const cloneData = [...tables2]
      setTables([...cloneData])
      const Array = getindex_third.slice(1);
      const clone_Tables = [...tables2];
      Array.forEach((e) => {
        const deleteObject = clone_Tables[e.indexco];
        deleteObject.splice(e.indexrow, 1);
      });
      setTables2([...clone_Tables]);
      setGetindex_third([])
      setSelectedCells_second([])
      setUniqueCombinationCount_third([])
    }
  }
  const MergeCellRow = (key_values) => {
    if (key_values === 'one') {
      const resultArray = getindex_first.slice(0, 1);
      const index = resultArray[0].indexrow
      const index1 = resultArray[0].indexco
      const key = 'cols'
      const cloneTables = [...tables]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = uniqueCombinationCount_first;
      const cloneData = [...tables]
      setTables([...cloneData])
      const Array = getindex_first.slice(1);
      const clone_Tables = [...tables];
      Array.forEach((e) => {
        const deleteObject = clone_Tables[e.indexco];
        deleteObject.splice(e.indexrow, 1);
      });
      setTables([...clone_Tables]);
      setGetindex_first([])
      setSelectedCells_first([])
      setUniqueCombinationCount_first([])

    } else if (key_values === 'two') {
      const resultArray = getindex_second.slice(0, 1);
      const index = resultArray[0].indexrow
      const index1 = resultArray[0].indexco
      const key = 'cols'
      const cloneTables = [...tables1]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = uniqueCombinationCount_second;
      const cloneData = [...tables1]
      setTables1([...cloneData])
      const Array = getindex_second.slice(1);
      const clone_Tables = [...tables1];
      Array.forEach((e) => {
        const deleteObject = clone_Tables[e.indexco];
        deleteObject.splice(e.indexrow, 1);
      });
      setTables1([...clone_Tables]);
      setGetindex_second([])
      setSelectedCells_second([])
      setUniqueCombinationCount_second([])

    } else {
      const resultArray = getindex_third.slice(0, 1);
      const index = resultArray[0].indexrow
      const index1 = resultArray[0].indexco
      const key = 'cols'
      const cloneTables = [...tables2]
      const datarow = cloneTables[index1]
      const object = datarow[index]
      object[key] = uniqueCombinationCount_third;
      const cloneData = [...tables2]
      setTables2([...cloneData])
      const Array = getindex_second.slice(1);
      const clone_Tables = [...tables2];
      Array.forEach((e) => {
        const deleteObject = clone_Tables[e.indexco];
        deleteObject.splice(e.indexrow, 1);
      });
      setTables2([...clone_Tables]);
      setGetindex_third([])
      setSelectedCells_third([])
      setUniqueCombinationCount_third([])
    }
  }
  const MergeCellRowAll = (key_values) => {
    if (key_values === 'one') {
      const resultArray = getindex_first.slice(0, 1);
      const index = resultArray[0].indexrow
      const index1 = resultArray[0].indexco
      const cloneTables = [...tables]
      const deleteObject = cloneTables[index1]
      deleteObject.splice(index, 1);
      const cloneData = [...tables]
      setTables([...cloneData])

    } else if (key_values === 'two') {
      const resultArray = getindex_second.slice(0, 1);
      const index = resultArray[0].indexrow
      const index1 = resultArray[0].indexco
      const cloneTables = [...tables1]
      const deleteObject = cloneTables[index1]
      deleteObject.splice(index, 1);
      const cloneData = [...tables1]
      setTables1([...cloneData])
    } else {
      const resultArray = getindex_third.slice(0, 1);
      const index = resultArray[0].indexrow
      const index1 = resultArray[0].indexco
      const cloneTables = [...tables2]
      const deleteObject = cloneTables[index1]
      deleteObject.splice(index, 1);
      const cloneData = [...tables2]
      setTables2([...cloneData])

    }
  }
  const changeLable = (value, key, getindex) => {
    setEditlable(value)
    const object = { ...dataText[getindex] };
    object[key] = value;
    const cloneData = [...dataText];
    cloneData[getindex] = { ...object };
    setDataText([...cloneData]);

  }
  const changeCheckbox = (value, key, getindex) => {
    setEditcheckboxvalues(value)
    const object = { ...datacheckbox[getindex] };
    object[key] = value;
    const cloneData = [...datacheckbox];
    cloneData[getindex] = { ...object };
    setDatacheckbox([...cloneData]);

  }
  const deleteDataText = (index) => {
    const cloneData = [...dataText]
    cloneData.splice(index, 1)
    setDataText([...cloneData])
  }
  const deletetable = (get_index, key_values) => {
    setSelectedCells_first([])
    setSelectedCells_second([])
    setSelectedCells_third([])
    setGetindex_first([])
    setGetindex_second([])
    setGetindex_third([])

    if (key_values === 'one') {
      setShowdeltable(false)
      const cloneData = [...datatable]
      cloneData.splice(get_index, 1)
      setDatatable([...cloneData])

    } else if (key_values === 'two') {
      setShowdeltable(false)
      const cloneData = [...datatable1]
      cloneData.splice(get_index, 1)
      setDatatable1([...cloneData])
    } else {
      setShowdeltable(false)
      const cloneData = [...datatable2]
      cloneData.splice(get_index, 1)
      setDatatable2([...cloneData])
    }


  }
  const handleCloseSavle = () => {
    setShowsave(false)
  }
  const OnCreate = async () => {
    setIsLoading(true);
    let images
    if (!file) {
      images = 0
    } else {
      let formData = new FormData();
      for (const key of Object.keys(file)) {
        formData.append("file_name", file[key]);
      }

      let profileImageReturnName = await axios.post("/api/document-management/req/upload", formData);
      images = Object.values(profileImageReturnName.data)[0]

      console.log("image=", images)
    }
    let informdata = {
      form_name: saveAs,
      formstatus: 0,
      created_by: '',
      InsertDataCheckbox: datacheckbox,
      InsertDataLable: dataText,
      InsertPositionOne: datatable,
      DataTablepositionOne: tables,
      InsertPositionsTwo: datatable1,
      DataTablepositiontwo: tables1,
      InsertPositionsThree: datatable2,
      DataTablepositionThree: tables2,
      InsertSizeForInput: listtext,
      InsertDataImage: selectedImage,
      file_name: images
    }
    console.log("informdata=", informdata)
    axios.post("/api/document-management/form/Insert-form", informdata).then((data) => {

      setIsLoading(false);
      setShowsave(false)
    }).catch((error) => {
      console.log(error)
      // httpErrorHandler(error)

    })
  }
  const OnclickTable = (index, index1, key_values) => {
    if (key_values === 'one') {
      setSelectedCells_second([])
      const number = parseInt(index + "" + index1);
      if (!uniqueCombinations_first.has(number)) {
        // If unique, update the set and the count

        setUniqueCombinations_first((prevSet) => new Set([...prevSet, number]));
        setUniqueCombinationCount_first((prevCount) => parseInt(prevCount + 1));
      } else {
        // If not unique, remove the old element and decrement the count
        setUniqueCombinations_first((prevSet) => {
          const newSet = new Set([...prevSet]);
          newSet.delete(number);
          return newSet;
        });

        setUniqueCombinationCount_first((prevCount) => parseInt(prevCount - 1));
      }

      setGetindex_first((prevArray) => {
        const existingElementIndex = prevArray.findIndex(
          (item) => item.indexrow === index && item.indexco === index1
        );
        if (existingElementIndex === -1) {
          return [...prevArray, { indexrow: index, indexco: index1 }];
        } else {
          const newArray = [...prevArray];
          newArray.splice(existingElementIndex, 1);
          return newArray;
        }
      });
      setWidthsize('');
      setHeightsize('');
      setIndex(index);
      setIndex1(index1);

    } else if (key_values === 'two') {
      setSelectedCells_first([])

      const number = parseInt(index + "" + index1);
      if (!uniqueCombinations_second.has(number)) {
        // If unique, update the set and the count

        setUniqueCombinations_second((prevSet) => new Set([...prevSet, number]));
        setUniqueCombinationCount_second((prevCount) => parseInt(prevCount + 1));
      } else {
        // If not unique, remove the old element and decrement the count
        setUniqueCombinations_second((prevSet) => {
          const newSet = new Set([...prevSet]);
          newSet.delete(number);
          return newSet;
        });

        setUniqueCombinationCount_second((prevCount) => parseInt(prevCount - 1));
      }
      setGetindex_second((prevArray) => {
        const existingElementIndex = prevArray.findIndex(
          (item) => item.indexrow === index && item.indexco === index1
        );
        if (existingElementIndex === -1) {
          return [...prevArray, { indexrow: index, indexco: index1 }];
        } else {
          const newArray = [...prevArray];
          newArray.splice(existingElementIndex, 1);
          return newArray;
        }
      });
      setWidthsize('');
      setHeightsize('');
      setIndex(index);
      setIndex1(index1);

    } else {
      setSelectedCells_first([])
      const number = parseInt(index + "" + index1);
      if (!uniqueCombinations_third.has(number)) {
        // If unique, update the set and the count

        setUniqueCombinations_third((prevSet) => new Set([...prevSet, number]));
        setUniqueCombinationCount_third((prevCount) => parseInt(prevCount + 1));
      } else {
        // If not unique, remove the old element and decrement the count
        setUniqueCombinations_third((prevSet) => {
          const newSet = new Set([...prevSet]);
          newSet.delete(number);
          return newSet;
        });
        setUniqueCombinationCount_third((prevCount) => parseInt(prevCount - 1));
      }
      setGetindex_second((prevArray) => {
        const existingElementIndex = prevArray.findIndex(
          (item) => item.indexrow === index && item.indexco === index1
        );
        if (existingElementIndex === -1) {
          return [...prevArray, { indexrow: index, indexco: index1 }];
        } else {
          const newArray = [...prevArray];
          newArray.splice(existingElementIndex, 1);
          return newArray;
        }
      });
      setWidthsize('');
      setHeightsize('');
      setIndex(index);
      setIndex1(index1);
    }

  };


  const SelectCell = (rowIndex, colIndex, key_values) => {
    if (key_values === 'one') {
      const isSelected = selectedCells_first.some((cell) => cell.row === rowIndex && cell.col === colIndex);
      if (isSelected) {
        setSelectedCells_first((prevSelected) =>
          prevSelected.filter((cell) => !(cell.row === rowIndex && cell.col === colIndex))
        );
      } else {
        setSelectedCells_first((prevSelected) => [...prevSelected, { row: rowIndex, col: colIndex }]);
      }

    } else if (key_values === 'two') {
      const isSelected = selectedCells_second.some((cell) => cell.row === rowIndex && cell.col === colIndex);
      if (isSelected) {
        setSelectedCells_second((prevSelected) =>
          prevSelected.filter((cell) => !(cell.row === rowIndex && cell.col === colIndex))
        );
      } else {
        setSelectedCells_second((prevSelected) => [...prevSelected, { row: rowIndex, col: colIndex }]);
      }

    } else {

    }

  };


  const changeText = (value, key, index) => {
    const object = { ...listtext[index] };
    object[key] = value;
    const cloneData = [...listtext];
    cloneData[index] = { ...object };
    setListText([...cloneData]);
  };
  const changeText2 = (value, key, index, index1) => {
    setInputValues(value)
    const cloneTables = [...tables]
    const datarow = cloneTables[index1]
    const object = datarow[index]
    object[key] = value;
    const cloneData = [...tables]
    setTables([...cloneData])
  };
  const changeText3 = (value, key, index, index1) => {
    setInputValues1(value)
    const cloneTables = [...tables]
    const datarow = cloneTables[index1]
    const object = datarow[index]
    object[key] = value;
    const cloneData = [...tables]
    setTables([...cloneData])
  };
  const changeTextForsize = (value, key, index) => {
    const object = { ...listtext[index] };
    object[key] = value;
    const cloneData = [...listtext];
    cloneData[index] = { ...object };
    setListText([...cloneData]);
    setListTextw([cloneData[index]]);
  };
  const Onclicktextlist = (index) => {
    setMenucondigions('input')
    const cloneData = listtext[index]
    cloneData.index = index
    setListTextw([cloneData])
  }
  const deletechange = (index) => {
    const cloneData = [...listtext]
    const cloneData1 = [...listtextw]
    cloneData.splice(index, 1)
    cloneData1.splice(index, 1)
    setListText([...cloneData])
    setListTextw([...cloneData1])
  }
  const onAddNewItem = () => {
    setDataText([...dataText, { name: 'Text', positionX: 50, positionY: 100, width: 0, height: 0, font: '15px', fontWeight: '', type: 'lable' }])
  }
  const onAddNewCheckbox = () => {

    const newcheckbox = { name: 'Checkbox', positionX: 0, positionY: 0, width: 50, height: 0, type: 'checkbox' }
    const cloneData = [...datacheckbox]
    cloneData.push(newcheckbox)
    setDatacheckbox([...cloneData])
  }
  const handlePictureChange = (event) => {
    setFile(event.target.files);
    const picture = event.target.files[0];
    const selectedFiles = event.target.files;
    const file_attachment = Array.from(selectedFiles);
    setSelectedImage([{ name: file_attachment[0].name, positionX: 100, positionY: 0, width: 100, height: 100, type: 'image' }])
    if (picture) {
      const pictureUrl = URL.createObjectURL(picture);
      console.log('pictureUrl=', pictureUrl)
      setPictureUrl(pictureUrl);
    }
  };
  const onCreateTable = () => {
    setSelectedCells_first([])
    setSelectedCells_second([])
    setSelectedCells_third([])
    if (datatable.length == 0) {
      setShow(false)
      setShowdeltable(true)
      if (!tableRow || !tableColumn) return
      const newItemtable = {
        name: '',
        positionX: 0,
        positionY: 0,
        width: 749,
        height: 200,
        countrow: tableRow,
        type: 'table',
      }


      const cloneData = [...datatable]
      cloneData.push(newItemtable)
      setDatatable([...cloneData])
      let item = [];
      for (let i = 0; i < tableColumn; i++) {
        let row = tableRow
        let data;
        if (row == 1) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 2) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 3) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
        } else if (row == 4) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
        } else if (row == 5) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 6) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 7) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 8) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 9) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 10) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        }
        item.push(data)
      }
      setTables(item)

    } else if (datatable1.length == 0) {
      setShow(false)
      if (!tableRow || !tableColumn) return
      const newItemtable1 = {
        name: '',
        positionX: 0,
        positionY: 141,
        width: 749,
        height: 200,
        type: 'table',
        countrow: tableRow,
      }
      const cloneData = [...datatable1]
      cloneData.push(newItemtable1)
      setDatatable1([...cloneData])
      let item1 = [];
      for (let i = 0; i < tableColumn; i++) {
        let row = tableRow
        let data;
        if (row == 1) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
        } else if (row == 2) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 3) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
        } else if (row == 4) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
        } else if (row == 5) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 6) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 7) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 8) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 9) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 10) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        }
        item1.push(data)
      }
      setTables1(item1)
    } else if (datatable2.length == 0) {
      setShow(false)

      if (!tableRow || !tableColumn) return
      const newItemtable2 = {
        name: '',
        positionX: 0,
        positionY: 290,
        width: 749,
        height: 200,
        type: 'table',
        countrow: tableRow,
      }
      const cloneData = [...datatable2]
      cloneData.push(newItemtable2)
      setDatatable2([...cloneData])
      let item2 = [];
      for (let i = 0; i < tableColumn; i++) {
        let row = tableRow
        let data;
        if (row == 2) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 3) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
        } else if (row == 4) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]
        } else if (row == 5) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 6) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 7) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 8) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 9) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        } else if (row == 10) {
          data = [{ name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }, { name: '', value: '1', cols: 1, rows: 1, width: 100, height: 50 }]

        }
        item2.push(data)
      }
      setTables2(item2)
    }
  }
  return (
    <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between' }}>
      <div style={{ width: 300, backgroundColor: '#dbeafe', display: 'flex', flexDirection: 'column' }}>
        <h6 style={{ marginLeft: 10, marginTop: 10, fontWeight: 'bold' }}>Control Panel </h6>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{
            cursor: 'pointer',
            width: 80,
            height: 80,
            backgroundColor: '#fff',
            borderRadius: 5,
            marginLeft: 10,
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
            boxShadow: active === 'table' ? '1px 2px 9px #F4AAB9' : ''
          }}
            onMouseOver={() => setActive('table')}
            onMouseLeave={() => setActive(null)}
            onClick={() => { handleShow() }}
          >
            <BorderAllIcon style={{ fontWeight: 'bold' }} />
            <small style={{ fontWeight: 'bold' }}>Table</small>
          </div>
          <div style={{
            cursor: 'pointer',
            width: 80,
            height: 80,
            backgroundColor: '#fff',
            borderRadius: 5,
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
            boxShadow: active === 'input' ? '1px 2px 9px #F4AAB9' : ''
          }}
            onMouseOver={() => setActive('input')}
            onMouseLeave={() => setActive(null)}
            onClick={() => { OnAddText() }}
          >
            <InputIcon style={{ fontWeight: 'bold' }} />
            <small style={{ fontWeight: 'bold' }}>Input</small>
          </div>
          <div style={{
            cursor: 'pointer',
            width: 80,
            height: 80,
            backgroundColor: '#fff',
            borderRadius: 5,
            marginTop: 5,
            marginRight: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
            boxShadow: active === 'Text' ? '1px 2px 9px #F4AAB9' : ''
          }}
            onMouseOver={() => setActive('Text')}
            onMouseLeave={() => setActive(null)}
            onClick={() => { onAddNewItem() }}
          >
            <TextFormatIcon style={{ fontWeight: 'bold' }} />
            <small style={{ fontWeight: 'bold' }}>Text</small>
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{
            cursor: 'pointer',
            width: 80,
            height: 80,
            backgroundColor: '#fff',
            borderRadius: 5,
            marginLeft: 10,
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
            boxShadow: active === 'Checkbox' ? '1px 2px 9px #F4AAB9' : ''
          }}
            onMouseOver={() => setActive('Checkbox')}
            onMouseLeave={() => setActive(null)}
            onClick={() => { onAddNewCheckbox() }}
          >
            <CheckBoxIcon style={{ fontWeight: 'bold' }} />
            <small style={{ fontWeight: 'bold' }}>Checkbox</small>
          </div>
          <div style={{
            cursor: 'pointer',
            width: 80,
            height: 80,
            backgroundColor: '#fff',
            borderRadius: 5,
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
            boxShadow: active === 'Upload' ? '1px 2px 9px #F4AAB9' : '',
          }}
            onMouseOver={() => setActive('Upload')}
            onMouseLeave={() => setActive(null)}
          >
            <input
              style={{ color: 'white', width: 10, display: 'none', cursor: 'pointer' }}
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handlePictureChange}
            />
            <CloudUploadIcon style={{ fontWeight: 'bold', cursor: 'pointer' }} />
            <label htmlFor="contained-button-file" style={{ fontWeight: 'bold', cursor: 'pointer' }}>
              <small style={{ fontWeight: 'bold' }}>Upload</small>
            </label>

          </div>
          <div style={{
            cursor: 'pointer',
            width: 80,
            height: 80,
            backgroundColor: '#fff',
            borderRadius: 5,
            marginTop: 10,
            marginRight: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
            boxShadow: active === 'Print' ? '1px 2px 9px #F4AAB9' : ''
          }}
            onMouseOver={() => setActive('Print')}
            onMouseLeave={() => setActive(null)}
            onClick={handlePrintToPDF}
          >
            <PrintIcon style={{ fontWeight: 'bold' }} />
            <small style={{ fontWeight: 'bold' }}>Print</small>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{ border: '1px solid #fff', marginLeft: 10, marginRight: 10, borderRadius: 5 }}
          >
          </div>
        </div>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{
            cursor: 'pointer',
            width: 80,
            height: 80,
            backgroundColor: '#fff',
            borderRadius: 5,
            marginLeft: 10,
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
            boxShadow: active === 'Chart' ? '1px 2px 9px #F4AAB9' : ''
          }}
            onMouseOver={() => setActive('Chart')}
            onMouseLeave={() => setActive(null)}
            onClick={() => { onAddNewCheckbox() }}
          >
            <ChatIcon style={{ fontWeight: 'bold' }} />
            <small style={{ fontWeight: 'bold' }}>Chart</small>
          </div>
          <div style={{
            cursor: 'pointer',
            width: 80,
            height: 80,
            backgroundColor: '#fff',
            borderRadius: 5,
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
            boxShadow: active === 'Close' ? '1px 2px 9px #F4AAB9' : '',
          }}
            onMouseOver={() => setActive('Close')}
            onMouseLeave={() => setActive(null)}
            onClick={() => { OncloseCreateformScreen() }}
          >
            <CancelIcon style={{ fontWeight: 'bold', cursor: 'pointer' }} />
            <small style={{ fontWeight: 'bold' }}>Close</small>
          </div>
          <div style={{
            cursor: 'pointer',
            width: 80,
            height: 80,
            backgroundColor: '#fff',
            borderRadius: 5,
            marginTop: 10,
            marginRight: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
            boxShadow: active === 'Save' ? '1px 2px 9px #F4AAB9' : ''
          }}
            onMouseOver={() => setActive('Save')}
            onMouseLeave={() => setActive(null)}
            onClick={() => { handlesaveShow() }}
          >
            <SaveAltIcon style={{ fontWeight: 'bold' }} />
            <small style={{ fontWeight: 'bold' }}>Save</small>
          </div>
        </div>

      </div>
      <div ref={componentRef} style={{ width: 794.2512, height: '297mm', backgroundColor: 'white', padding: 10, border: '1px solid gray' }}>
        <ModalSaveTable
          saveAs={saveAs}
          setSaveAs={setSaveAs}
          showsave={showsave}
          handleCloseSavle={handleCloseSavle}
          OnCreate={OnCreate}
          isLoading={isLoading}
        />
        <ModalTable
          show={show}
          handleClosedel={handleClosedel}
          setTableRow={setTableRow}
          setTableColumn={setTableColumn}
          onCreateTable={onCreateTable}
        />
        {
          datatable.map((el, index) => {
            if (el.type === 'table') {
              return (
                <Rnd
                  key={index}
                  default={{
                    x: el.positionX,
                    y: el.positionY,
                  }}
                  onDragStop={(e, d) => onDragStoptable(e, d, index, 'one')}
                  onClick={() => { Onclicktableindex(index, 'one') }}
                >
                  <table style={{ border: '1px solid gray' }}>
                    {
                      tables && tables.map((item, rowIndex) => {
                        return (
                          <>
                            <tr key={rowIndex} style={{ border: '1px solid gray' }}>
                              {
                                item.map((data, colIndex) => {
                                  const cellProps = {
                                    key: colIndex,
                                    style: {
                                      width: `${data.width}px`,
                                      border: '1px solid gray',
                                      backgroundColor: selectedCells_first.some(
                                        (selectedCell) => selectedCell.row === rowIndex && selectedCell.col === colIndex
                                      )
                                        ? '#DBEAFE'
                                        : 'white',
                                    },
                                    colSpan: data.cols,
                                    rowSpan: data.rows,
                                  };
                                  if (data.merged) {

                                    return null;
                                  }
                                  return (
                                    <>
                                      <td {...cellProps}>
                                        <textarea value={data?.name}
                                          onChange={(e) => { changeText_values_table(e.target.value, 'name', rowIndex, colIndex, 'one') }}
                                          onClick={() => {
                                            OnclickTable(colIndex, rowIndex, 'one');
                                            SelectCell(rowIndex, colIndex, 'one')
                                          }}
                                          style={{ border: 'none', width: '100%', height: `${data?.height}px`, marginTop: 5 }} />
                                      </td>
                                    </>
                                  )
                                })
                              }
                            </tr>
                          </>
                        )
                      })
                    }
                  </table>
                </Rnd>
              )
            }
          })
        }
        {
          datatable1.map((el, index) => {
            if (el.type === 'table') {
              return (
                <Rnd
                  key={index}
                  default={{
                    x: el.positionX,
                    y: el.positionY,

                  }}
                  onDragStop={(e, d) => onDragStoptable(e, d, index, 'two')}
                  onClick={() => { Onclicktableindex(index, 'two') }}
                >
                  <table style={{ border: '1px solid gray' }}>
                    {
                      tables1 && tables1.map((item, rowIndex) => {
                        return (
                          <>
                            <tr key={rowIndex} style={{ border: '1px solid gray' }}>
                              {
                                item.map((data, colIndex) => {
                                  const cellProps = {
                                    key: colIndex,
                                    style: {
                                      width: `${data.width}px`,
                                      border: '1px solid gray',
                                      backgroundColor: selectedCells_second.some(
                                        (selectedCell) => selectedCell.row === rowIndex && selectedCell.col === colIndex
                                      )
                                        ? '#DBEAFE'
                                        : 'white',
                                    },
                                    colSpan: data.cols,
                                    rowSpan: data.rows,
                                  };
                                  if (data.merged) {

                                    return null;
                                  }
                                  return (
                                    <>
                                      <td {...cellProps}>
                                        <textarea value={data?.name}
                                          onChange={(e) => { changeText_values_table(e.target.value, 'name', rowIndex, colIndex, 'two') }}
                                          onClick={() => {
                                            OnclickTable(colIndex, rowIndex, 'two');
                                            SelectCell(rowIndex, colIndex, 'two')
                                          }}

                                          style={{ border: 'none', width: '100%', height: `${data?.height}px`, marginTop: 5 }} />
                                      </td>
                                    </>
                                  )
                                })
                              }
                            </tr>
                          </>
                        )
                      })
                    }
                  </table>
                </Rnd>
              )
            }
          })
        }
        {
          listtext && listtext.map((e, index) => {
            return (
              < Rnd
                default={{
                  x: e.positionX,
                  y: e.positionY,
                }}
                onDragStop={(e, d) => onDragStopinput(e, d, index)}
              >
                <ComponentInput
                  index={index}
                  changeText={changeText}
                  Onclicktextlist={() => { Onclicktextlist(index) }}
                  e={e}
                />
              </Rnd>
            )
          })
        }
        {
          dataText && dataText.map((el, index) => {
            return (
              <>
                <Rnd
                  key={index}
                  default={{
                    x: el.positionX,
                    y: el.positionY,
                  }}
                  onDragStop={(e, d) => onDragStopDataText(e, d, index)}
                  onClick={() => onClickDataText(index)}
                >
                  <small style={{ fontSize: el.font, fontWeight: el.fontWeight }}>{el.name}</small>
                </Rnd>
              </>
            )

          })
        }
        {
          datacheckbox && datacheckbox.map((el, index) => {
            return (
              <Rnd
                key={index}
                default={{
                  x: el.positionX,
                  y: el.positionY,

                }}
                onDragStop={(e, d) => onDragStopcheckbox(e, d, index)}
                onClick={() => OnClickCheckbox(index)}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <input type="checkbox" style={{ cursor: 'pointer' }} />
                  <small style={{ marginLeft: 5 }}>{el.name}</small>
                </div>
              </Rnd>
            )
          })
        }
        {
          selectedImage && selectedImage.map((e, index) => {
            return (
              <Rnd
                default={{
                  x: e.positionX,
                  y: e.positionY,
                }}
                onDragStop={(e, d) => { onDragImagelogo(e, d, index) }}
                onClick={() => { OnClickCheckimage(index) }}
              >
                {pictureUrl && (
                  <img src={pictureUrl} alt="Selected Picture" style={{ width: `${e?.width}px`, height: `${e?.height}px` }} />
                )}
              </Rnd>
            )
          })
        }
      </div>
      <div style={{ backgroundColor: '#dbeafe', width: 300 }}>
        {
          menucondigions === 'input' ? (<>
            {
              listtextw && listtextw.map((e, index) => {
                return (
                  <div key={index} style={{ width: '100%' }}>
                    <Input
                      changeTextForsize={changeTextForsize}
                      deletechange={deletechange}
                      e={e}
                      index={e.index}
                    />
                  </div>
                )
              })
            }
          </>) : menucondigions === 'table' ? (
            <div style={{ width: '100%' }}>
              <TableMenu
                datatable={datatable}
                datatable1={datatable1}
                inputValues={inputValues}
                inputValues1={inputValues1}
                changeText2={changeText2}
                changeText3={changeText3}
                changeTextheight={changeTextheight}
                changeTextwidth={changeTextwidth}
                MergeCellcolumn={MergeCellcolumn}
                MergeCellRow={MergeCellRow}
                MergeCellRowAll={MergeCellRowAll}
                deletetable={deletetable}
                showdeltable={showdeltable}
                showdeltable1={showdeltable1}
                get_index={get_index}
                widthsize={widthsize}
                heightsize={heightsize}
                index={index}
                index1={index1}

              />
            </div>
          ) : menucondigions === 'Text' ? (
            <div style={{ width: '100%' }}>
              <TextMenu
                dataText={dataText}
                editlable={editlable}
                changeLable={changeLable}
                get_index={get_index}
                deleteDataText={deleteDataText}
              />
            </div>
          ) : menucondigions === 'CheckBox' ? (
            <>
              <CheckBoxMenu
                datacheckbox={datacheckbox}
                editcheckboxvalues={editcheckboxvalues}
                changeCheckbox={changeCheckbox}
                get_index={get_index}
                deletecheckbox={deletecheckbox}

              />

            </>) : menucondigions === 'Image' ? (

              <>
                <ImageMenu
                  get_index={get_index}
                  selectedImage={selectedImage}
                  ing_width={ing_width}
                  ing_heigh={ing_heigh}
                  changeTextImage_width={changeTextImage_width}
                  changeTextImage_height={changeTextImage_height}
                />


              </>) : null
        }

      </div>
    </div>
  )
}
function ComponentInput({ Onclicktextlist, changeText, e, index }) {
  return (
    <>
      <input
        style={{
          border: '1px solid #5EBFE3',
          width: `${e.width}px`,
          height: `${e.height}px`,
          border: 'none',
        }}
        placeholder="................................................................................................................................................................................................................................................................................................"
        value={e?.name}
        onChange={(el) => changeText(el.target.value, "name", index)}
        onClick={() => { Onclicktextlist(index) }}

      />
    </>

  )
}

