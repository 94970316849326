// export const api = 'http://192.168.30.56:8080'
// export const api = 'http://192.168.40.146:5500'
// export const api = 'http://192.168.40.146:5500'
// export const api = 'https://api-office.psvsystem.com'
// export const api = 'https://api.​phongsavanhgroup.com​'
// export const api = 'http://192.168.30.215:5500'
// export const api = 'http://192.168.30.64:5500'
// export const api = 'http://192.168.30.2:5500'
// export const api = 'http://192.168.30.6:2023'
export const api = 'https://testing-api.psvsystem.com'
